/* This is where you put global styles */
:global {
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-font-feature-settings: "liga", "kern";
    text-rendering: optimizelegibility;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
  }
}
